import React from 'react';
import { useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import Title from '../components/Title';
import Button from '../components/Button';
import SnsShareButtons from '../components/SnsShareButtons';
import Seo from '../components/Seo';
import '../pages/selected/style.sass';

export default props => {
  const PostContent = HTMLContent || Content;
  const { data } = props.pageContext;
  console.log(data);

  const list = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  console.log(list);

  return (
    <Layout>
      <Seo
        title={data.title}
        description={data.description}
        href={props.location.href}
      />
      <Title>{data.title}</Title>
      <PostContent className="content" content={data.body} />
      <div className="selected__section">
        <SnsShareButtons
          location={props.location}
          title={`【夢占い】${data.title}。${data.description} 詳しくはマダムたぬきの夢占いで`}
        />
      </div>
      <div className="selected__nav">
        {/* <div className="selected__navItem">
          <Button label="もどる" to={`/fortune/${data.parentId}`} />
        </div> */}
        <div className="selected__navItem">
          <Button label="トップへ戻る" to={`/`} />
        </div>
      </div>
    </Layout>
  );
};
